﻿/// <reference path="../_Variables.less" />

.footer {
    //margin-top:30px;
    background:@color-footer-background;

    .footer-content {
        padding: 0;// 30px 0 0 0;
        color: @color-footer-text;

        a {
			color: @color-footer-text;
            text-decoration: underline;
            font-weight: normal;

            &.block-link  {
                color:inherit;

                &.link{
                    color: @color-footer-text;
                    font-weight:normal;
                }

				&:hover {
					text-decoration: none;
				}
            }
            &:hover{
                /*color:@color-brand-primary-1;*/
                text-decoration:underline;
				color: @color-brand-primary-3;
            }
            
			.link {
				color: @color-footer-link;
				font-weight: normal;
			}
        }

		.paragraph-small ul {
			padding-left: 18px;
		}

		/*.icon{
			path{
				fill: @color-brand-primary-4;
			}
		}*/

		.twitter-timeline{
			color:@color-brand-primary-1;
		}
		/*.contact-data{
			a{
				font-weight:normal;
				&:hover{
					text-decoration:underline;
				}
			}
		}*/
		.clinics-list, .experience-list {
			list-style:none;
			padding:0;
			margin:0;
			li{
				padding-left: 1em; 
				text-indent: -.7em;
				a{
					line-height: 2.1429em;
					font-size: 0.8750em;
					margin-bottom: 0.0000em;
					font-weight:normal;
					&:hover{
						text-decoration:underline;
					}
				}
			}
		}
    }

    .footer-nav {
        margin-top:@padding-medium;
        background: @color-footerbar;

        &, a {
            //color: @color-white;
        }

		 path {
            //fill: @color-brand-primary-4;
        }

        a {
            display: inline-block;
			font-weight:normal;

            &:hover, &:focus {
                /*color: @color-brand-primary-1;*/

                path {
                    //fill: @color-brand-primary-1;
                }
            }
        }
        .internal-links-container{
            text-align:center;
        }
    }

    .footer-icon {
		height: 24px;
		margin-right: 10px;
		vertical-align: top;
		width: 24px;

		svg {
			path {	
				fill: @color-footer-icons;
			}
		}
    }

    .footer-nav-icon {
        vertical-align:bottom;
        padding-bottom:20px;

		svg{
			path {
				fill:@color-footer-social-media-icons;
			}

			 &:hover, &:focus {
				path {
					fill:@color-footer-social-media-icons-hover;
				}
			}
		}
    } 

    h3 {
		font-size: 24px;
		font-weight: normal;
        margin-bottom: 30px;
        padding-bottom: 24px;
        border-bottom: 1px solid @color-footer-border-bottom;
        color: @color-footer-heading;
    }

    .footer-bar-links {
        vertical-align: bottom;
        margin: 0;
        list-style: none;
        padding: 0;
        min-height: 60px;
        display: inline-block;

        li {
            float: left;
            margin: 0;
            margin-right: 30px;
            line-height: 60px;
        }

		p{
			height: 60px;
			display: table-cell;
			vertical-align: middle;
		}

        &.internal-links {
            @media screen and (min-width:@medium_breakpoint-min) {
                text-align: right;
                li {
                    /*margin-left: 30px;*/
                    margin-right: 0;
                }
            }
        }
    }
}
