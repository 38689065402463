﻿/// <reference path="../_Variables.less" />

.text-widget-date {
    display: inline-block;
    vertical-align: text-top;
    text-align: center;
    margin-right: 20px;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    float: left;
    width: 3em;

    .text-widget-date-day {
        background:@color-text-widget-date-day;
    }

    .text-widget-date-month {
        background: @color-text-widget-date-month;
        font-weight:bold;
        &:after {
            display: block;
            content: ' ';
        }
    }

    .text-widget-date-day, .text-widget-date-month {
        display: block;
        font-size: 14px;
        color:@color-text-widget-date-text-color;
        padding:3px;
    }
}
