﻿/// <reference path="../_Variables.less" />

@media (min-width: @screen-sm-min) {
	.pull-right-sm {
		float: right;
	}

	.clear-left-sm {
		clear: left;
	}

	.pull-left-sm {
		float: left;
	}

	.clear-right-sm {
		clear: right;
	}
	.content-blocks-home a div div{
		max-height:240px;
	}
}

.content-blocks {
	&.content-blocks-home {
		a{
			div{
				margin-top:5px;
				div{
					img{
						left:0;
						max-height:257px;
					}
				}
			}
		}
	}
}

.project-banner, .page-banner{
    text-decoration:none;

    &:hover{
        text-decoration:none;
    }
}

.content-blocks-home {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;

	.page-banner {
		cursor: pointer;
		-webkit-flex: 0 0 auto;
		flex: 0 0 auto;

		&:hover {
			h2 {
				color: @color-brand-primary-3;
			}
		}
	}
}