﻿/// <reference path="../_Variables.less" />
/// <reference path="../Mixins.less" />

@min-form-input-width: 100%;
@min-form-input-width-small: 150px;
@min-form-label: 150px;


@color-brand-error: red;
@input-text-color: #333;
@form-border-color: #CCCCCC;

.nsas, .form .nsas {
	float: right;
	margin-left: -100000px !important;
	display: block;
	margin-top: -1000px !important;
	position: absolute;
	left: -1000px;
	top: -1000px;

	input {
		border: 0;
	}
}

.form, .form-display, .form-horizontal {
	.input-validation-error {
		border: 1px solid @color-brand-error !important;
	}

	.icon-checkmark, .icon-error {
		margin-bottom: 0;
		position: absolute !important;
		right: 5px;
		//bottom: 13px;
		top: 13px;

		@media(max-width: @screen-md-min) {
			top: 41px;
		}
	}

	.validation-summary-warning {
		color: @color-brand-error;
		vertical-align: middle;
		.fontSize(16);

		.validation-summary-icon {
			vertical-align: middle;
			display: inline-block;
			margin-right: @padding-default;
		}

		p {
			color: @color-brand-error;
		}
	}

	.field-validation-error {
		margin-top: @padding-small;
		display: block;
	}

	.label-default, .control-label {
		font-weight: normal;
		display: inline-block;
		min-width: @min-form-label;
		margin-bottom: 0.2em;
	}

	.input-textarea {
		max-width: 100%;
		width: 100%;
		min-width: 100%;
		padding: 0.25em;
		border: 1px solid @form-border-color;
		color: @input-text-color;
		min-height: 10em;
		vertical-align: top;
		margin-bottom: 5px;
	}

	.input-text-default, .form-control {
		padding: 0.25em;
		border: 1px solid @form-border-color;
		color: @input-text-color;
		min-width: @min-form-input-width;
		vertical-align: middle;
		min-height: 25px;

		&.input-text--small {
			min-width: @min-form-input-width-small;
		}

		&.input-text--large {
			vertical-align: top;
			max-width: 80%;
			max-width: ~"calc(100% - 154px)";
			resize: vertical; /*will prevent resizing horizontally*/
			width: 100%;
			min-height: 100px;
		}
	}

	.form-row, .form-group {
		margin: @padding-small 0;

		&.form-row-no-label {
			margin-left: @min-form-label;
		}
	}

	.form-group {
		line-height: 2;

		&:after {
			display: table;
			content: ' ';
			clear: both;
		}
	}

	.form-row-text-large {
		vertical-align: top;
	}

	.form-row-input-list {
		border: 0;
		padding: 0;
		margin: @padding-medium 0;
		max-width: @min-form-input-width;

		legend {
			margin-bottom: @padding-small;
		}

		.label-input-list {
			display: block;
			padding: @padding-small 0;
			.transition(background 0.2s linear);

			input {
				margin-right: @padding-default;
				vertical-align: middle;
			}

			input[type='checkbox'], input[type='radio'] {
				margin-bottom: 2px;
			}

			&:hover {
				background: rgba(255,255,255,0.3);
				cursor: pointer;
			}
		}
	}

	.form-row-select {
		.select-dropdown {
			min-width: @min-form-input-width;
			padding: @padding-small;
			border: 1px solid @form-border-color;
		}
	}

	.form-section {
		border: 0;
		padding: 0;
		margin: @padding-default 0;

		.form-section-header {
			.fontSize(18);
			color: @color-brand-primary-2;
			margin: @padding-default 0;
			font-weight: bold;
		}

		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	.control-validation-container {

		.icon-checkmark, .icon-error {
			top: 13px !important;
		}

		.placeholder() {
			color: #999;
			opacity: 1;
		}

		*::-webkit-input-placeholder {
			.placeholder();
		}

		*::-moz-placeholder {
			.placeholder();
		}

		*:-ms-input-placeholder {
			.placeholder();
		}

		position: relative;
		display: inline-block;
		width: 100%;

		.hidden-as-form-validation {
			//display: none;
			display: inline-block;
			opacity: 0;
			.transition(all 0.5s cubic-bezier(0.5, -0.5, 0.5, 1.5));
			position: absolute;
			right: 0.5em;
			top: 0.6em;
			.scale(0.1);
		}

		.icon-spacer, .hidden-as-form-validation {
			width: 1em;
			height: 1em;
			vertical-align: middle;
			margin-left: .5em;
		}

		.icon-spacer {
			display: inline-block;
		}
	}


	.valid {

		& + .icon-valid {
			.scale(1);
			opacity: 1;
		}

		& + span + span + .icon-spacer {
			//   display: none;
		}
	}

	.input-validation-error {
		& + span + .icon-invalid {
			.scale(1);
			opacity: 1;
		}

		& + span + span + .icon-spacer {
			//  display: none;
		}
	}

	.input-button-container {
		display: inline-block;
		position: relative;

		.input-button.icon {
			height: 18px;
			width: 18px;
			display: block;
			position: absolute;
			right: 3px;
			top: 50%;
			margin-top: -9px;

			&:hover, &:focus {
				svg {
					.fillSvg(@color-brand-primary-2);
				}
			}
		}
	}
}

.form-display {
	.form-row {
		margin: @padding-default 0;
	}
}

.shortcut {
	display: block;
	color: @form-border-color;
	font-weight: bold;
	.fontSize(16);
	padding: 0 @padding-default;
}

.button-container {
	&.button-container-aside {
		text-align: right;
	}
}
