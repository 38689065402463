﻿/// <reference path="../_Variables.less" />
/// <reference path="../Bootstrap.less" />

.more-treatments {
	margin-top: 10px;
}

.title-project {
	margin-left: 10px;
}

.pagination-container {
	text-align: center;

	.pagination {
		li::before {
			content: "";
		}

		.current {
			color: #fff;
			background-color: @color-pagination-current;
		}
	}
}

.container-projects {
	margin-top: 30px;

	@media screen and (min-width:@medium_breakpoint-min) {
		.row {
			margin: 0;
		}
	}

	.col-xs-6, .col-sn-4, .col-md-3 {
		padding: 0;
	}

	.project-tile {
		padding: 10px;
		overflow: hidden;

		img {
			max-width: 100%;
			-moz-transition: all 0.5s;
			-webkit-transition: all 0.5s;
			transition: all 0.5s;
		}
		/*&:hover img {
			-moz-transform: scale(1.2);
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}*/
	}
}

.project-preview {
	display: block;
	position: relative;
	overflow: hidden;
	/*img {
		filter: brightness(60%);
		-webkit-filter: brightness(60%);
		-moz-filter: brightness(60%);
		-o-filter: brightness(60%);
		-ms-filter: brightness(60%);
	}*/
	.project-title {
		position: absolute;
		font-weight: normal;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		display: block;
		//background: fadeout(@color-brand-primary-1, 20%);
		background: fade(@color-brand-primary-3, 0%);
		font-size: 0.8em;
		overflow: hidden;

		@media screen and (min-width:@medium_breakpoint-min) {
			font-size: 1em;
			top: 0;
			//opacity: 0;
			background: rgba(112, 128, 172, 0);
			transition: all 0.3s ease-in-out;
			font-weight: bold;
		}
	}


	&:hover, &:focus {
		.project-title {
			//opacity: 1;
			background: fade(@color-brand-primary-3, 60%);

			.project-title-preview {
				color: white !important;
				transition: color 0.3s linear;
			}
		}
	}



	.project-title-preview {
		width: 100%;
		height: 100%;
		display: table;
		color: transparent;
		overflow: hidden;

		.project-title-preview-helper {
			width: 100%;
			height: 100%;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			padding: 0.3em;
		}
	}

	.project-no-images {
		background: @color-brand-primary-3;
		min-width: 100%;
		/*min-height: 200px;*/
		position: relative;
		padding-top: 75%;

		&:hover {
			background: @color-brand-primary-1;
		}

		.project-title {
			opacity: 1;
			background: none;
			color: @color-brand-tertiary-5;
			text-align: center;
			top: 45%;
		}
	}
}

@media(max-width:@screen-md-min) {
	.project-title {
		background: fade(@color-brand-primary-3, 60%) !important;

		.project-title-preview {
			color: white !important;
		}
	}
}
