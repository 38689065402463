﻿@import (less) "../_Variables.less";

.table {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	border-bottom: 2px solid @color-brand-tertiary-2;
	margin-top: 5px;
	margin-bottom: 15px;

	&-header {
		font-weight: bolder;
		border-bottom: 2px solid @color-brand-tertiary-2;
	}

	&-row {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;

		&-item {
			display: flex;
			flex-flow: row nowrap;
			flex: 1;
			padding: 20px;
			word-break: break-word;
			//justify-content: space-between;
			&:first-child {
				justify-content: flex-start;
				flex-basis: 30%;
			}

			&:last-child {
				justify-content: flex-end;
			}
		}
	}

	@media(max-width: @screen-sm-min) {
		&-header {
			.table-row-item {
				&:not(:first-child) {
					display: none;
				}
			}
		}

		&-row {
			flex-wrap: wrap;

			&-item {
				flex-wrap: wrap;
				flex: 1 0 33%;

				&:first-child {
					flex: 0 1 100%;
				}
			}
		}
	}
}

.select-error {
	border: 1px solid red;
}

form {
	.customer-info-container {
		border-bottom: 2px solid @color-brand-tertiary-2;
		margin-bottom: 25px;

		h2 {
			margin-bottom: 25px;
		}
	}

	.invoice-form {
		.customer-info-container {
			border-bottom: none;
		}
	}

	.label-checkbox-container {
		@media(max-width: @screen-md-min) {
			flex-flow: row !important;
			text-align: left !important;

			input[type="checkbox"] {
				margin-top: 0;
			}

			label:first-child {
				width: 0;
				flex: 0;
			}
		}
	}

	.label-input-container {
		display: flex;
		flex-flow: row;
		align-items: center;
		margin-bottom: 20px;
		position: relative;

		@media(max-width: @screen-md-min) {
			flex-flow: column;

			&:last-child {
				display: block;

				input, p {
					display: inline-block;
				}
			}

			label {
				width: 100%;
				font-weight: bold;
			}

			input {
				width: 100%;
			}

			&.postal-city-container {
				display: flex;
				flex-flow: row;
				flex-wrap: wrap;

				label {
					flex: 0 1 auto;
				}

				input {
					flex: 1;
				}
			}
		}

		label {
			flex: 0 1 30%;
			font-weight: bold !important;
		}

		input {
			flex: 1 0 auto;
			border: 1px solid @color-brand-tertiary-2;
			padding: 7px;

			&.row-3 {
				flex: 0 1 20%;
				margin-right: 10px;

				@media(max-width: @screen-md-min) {
					max-width: 30%;
				}
			}

			&[type="checkbox"] {
				flex: 0 1 auto;
				margin-left: 5px;
				margin-right: 10px;
				margin-top: 2px !important;

				@media(max-width: @screen-md-min) {
					width: auto;
				}
			}
		}
	}

	select {
		height: 35px;
		width: 60px;
		padding-left: 15px;
	}

	.order-button {
		float: right;
		width: 70%;

		@media(max-width: @screen-md-min) {
			width: 100%;
		}
	}

	.euro-sign {
		margin-left: 5px;
	}
}


.total-costs-overview {
	display: flex;
	margin-left: auto;
	flex: 1 0 auto;
	max-width: 40%;
	flex-flow: column;
	margin-bottom: 25px;

	@media(max-width: @screen-md-min) {
		max-width: 90%;
	}

	.costs-container {
		display: flex;
		justify-content: flex-end;
		padding-right: 20px;

		span {
			text-align: right;
			margin-bottom: 2px;

			&:nth-child(2), &.shipping-costs-free {
				flex: 0 0 60px;
				margin-left: 5px;
			}
		}

		&:nth-child(3) {
			border-bottom: 2px solid @color-brand-tertiary-2;
			padding-bottom: 10px;
			margin-bottom: 10px;
		}

		.shipping-costs-free {
			margin-left: 19px !important;
		}
	}

	.text-red {
		color: red;
	}
}


.product {
	@media(max-width: @screen-md-min) {
		&:not(:last-child) {
			border-bottom: 1px solid #cdcdcd;
		}
	}

	span {
		margin-bottom: 0px;
	}

	.euro-sign {
		margin-right: auto;
	}

	.product-icon-info-container {
		display: block;

		.icon-container {
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
			margin-top: 4px;
		}

		.product-info-container {
			display: inline-block;

			.product-name {
				display: block;
			}

			.product-liters {
				display: block;
			}

			.product-extra-note {
				display: block;
			}
		}
	}
}

.not-bold {
	font-weight: normal;
	margin: 0;
	margin-left: 5px;
}
