﻿/// <reference path="../_Variables.less" />
/// <reference path="../Bootstrap.less" />
/// <reference path="../Layout/Body.less" />

.carousel-container {
	background: #E3E3E3;
}

.jumbo-carousel {
	margin: 0 auto;
	max-width: 2000px;

	p {
		margin-top: 1.6667em;
		margin-bottom: 0.0000em;
	}

	.carousel-indicators {
		bottom: 2px;
		margin: 0 auto;
		left: 0;
		right: 0;

		@media screen and (min-width:@medium_breakpoint-min) {
			bottom: 10px;
		}

		li {
			background: @color-white;
			border-color: @color-brand-primary-2;

			&.active {
				background: @color-brand-primary-1;
			}

			&:hover {
				background-color: @color-brand-primary-3;
			}
		}
	}
}

.header-carousel-caption {
	left: 0;
	right: 0;
	bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	max-width: 80%;
	width: 100%;
	top: 0;
	/*strong {
		font-weight:400;
	}*/
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.3em; /* Adjusts for spacing */
	}

	.carousel-caption-helper {
		display: inline-block;
		vertical-align: middle;

		.undertext {
			@media(max-width: @screen-sm-min) {
				font-size: 0.875em;
			}
		}
		/*position:absolute;
        bottom:15%;*/
	}

	@media screen and (min-width:@medium_breakpoint-min) {
		padding-bottom: 20px;
	}

	@media screen and (min-width:@large_breakpoint-min) {
		max-width: 950px;
	}

	margin: 0 auto;
	text-align: left;
	text-shadow: none;

	p {

		@media screen and (min-width:@medium_breakpoint-min) {
			line-height: 2;
		}



		span {
			display: inline-block;
			background: fadeout(@color-black,50%);
			/*background: @color-brand-primary-1;*/
			line-height: 1.5;
			/*@media screen and (min-width:@medium_breakpoint-min) {
                max-width: 30em;
            }*/
			strong {
				@media screen and (min-width:@medium_breakpoint-min) {
					font-size: 30px;
				}
			}
		}

		span, > a {
			padding: 5px 10px;

			@media screen and (min-width:@medium_breakpoint-min) {
				padding: 10px;
			}
		}

		> a {
			background: @color-button;
			text-decoration: none;
			color: @color-button-color;
			font-weight: bold;
			border-radius: 5px;

			&:hover, &:focus {
				background: @color-button-hover;
			}
		}
	}
}

.content-carousel {
	width: 100%;
	margin: 30px 0;

	.img-container {
		-webkit-background-size: cover !important;
		-moz-background-size: cover !important;
		-o-background-size: cover !important;
		background-size: cover !important;
		height: 620px;

		@media(max-width:@screen-md-max) {
			height: 535px;
		}

		@media(max-width:@screen-sm-max) {
			height: 425px;
		}

		@media(max-width:@screen-xs-max) {
			height: 325px;
		}
	}

	.carousel-indicators {
		margin: 20px 0 0;
		overflow: auto;
		position: static;
		text-align: left;
		white-space: nowrap;
		width: 100%;
		height: 135px;
	}

	.carousel-indicators li {
		background-color: transparent;
		-webkit-border-radius: 0;
		border-radius: 0;
		display: inline-block;
		height: auto;
		margin: 0 !important;
		margin-right: 10px !important;
		width: auto;
	}

	.carousel-indicators li .img-container {
		display: block;
		opacity: 0.5;
		height: 125px;
		width: 185px;
		overflow: hidden;
		-webkit-background-size: cover !important;
		-moz-background-size: cover !important;
		-o-background-size: cover !important;
		background-size: cover !important;
	}

	.carousel-indicators li.active .img-container {
		opacity: 1;
	}

	.carousel-indicators li:hover .img-container {
		opacity: 0.75;
	}

	.carousel-outer {
		position: relative;
	}
}

/*
.content-carousel {
    height:600px;
    position:relative;

    .content-carousel-slides {
        cursor:move;
        height:500px;
        width:100%;
        position:absolute;
        top:0;
        left:0;
    }

    .jssort01 {
        position:absolute;
        .p {
            position:absolute;
            width:60px;
            height:60px;
        }

        .w {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }

        .t {
                position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
        }

        .c {
                position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
    border: #000 2px solid;
    box-sizing: content-box;

    // background???
        }
    }
}*/

.carousel-fade {
	.carousel-inner {
		.item {
			transition-property: opacity;
		}

		.item,
		.active.left,
		.active.right {
			opacity: 0;
		}

		.active,
		.next.left,
		.prev.right {
			opacity: 1;
		}

		.next,
		.prev,
		.active.left,
		.active.right {
			left: 0;
			transform: translate3d(0, 0, 0);
		}
	}

	.carousel-control {
		z-index: 2;
	}
}
