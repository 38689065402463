﻿//*Colors*/
@color-brand-primary-1:#00539f;
@color-brand-primary-2:#4b91c7;
@color-brand-primary-3:#4ca12f;
//@color-brand-primary-4:#404040;

@color-brand-secondary-1:#ee5a28;
//@color-brand-secondary-2:#F2f2f2;
//@color-brand-secondary-3:#404040;
//@color-brand-secondary-4:#404040;
//@color-brand-secondary-5:#808080;

@color-brand-tertiary-1: #696969;
@color-brand-tertiary-2: #cdcdcd;
@color-brand-tertiary-3: #e6e6e6;
@color-brand-tertiary-4: #f0f0f0;
@color-brand-tertiary-5: #ffffff;

//*Header*//
@color-header-link:				@color-brand-tertiary-1;
@color-header-background:		@color-brand-tertiary-5;
@color-header-icon:				@color-brand-primary-1;
@color-header-link-hover:		@color-brand-primary-1;
@color-heading-hover :			@color-brand-primary-1;
@color-header-language-select:	@color-white;

//*Menu*//
@color-active-menu-item:		@color-brand-primary-3;
@color-menu-background:			@color-brand-primary-1;
@color-menu-text:				@color-white;
@color-menu-title-mobile:		@color-white;
@color-menu-icon-mobile:		@color-white;

//*Content*//
@color-h1:				@color-brand-primary-1;
@color-h2:				@color-brand-primary-1;
@color-paragraph:				@color-brand-tertiary-1;
@color-body-background:			@color-brand-tertiary-5;

//*Footer*//
@color-footer-background:		@color-brand-tertiary-4;
@color-footer-text:				@color-brand-tertiary-1;
@color-footer-heading:			@color-brand-tertiary-1;
@color-footer-link:				@color-brand-tertiary-1;
@color-footer-link-hover:				@color-brand-primary-3;

@color-footer-icons:			@color-brand-tertiary-1;
@color-footer-social-media-icons: @color-brand-tertiary-5;
@color-footer-social-media-icons-hover:	@color-brand-primary-3;

@color-footerbar:				@color-brand-tertiary-1;
@color-footer-border-bottom:	@color-brand-tertiary-2;

//**//
@color-news-item-title-hover:		@color-brand-primary-1;
@color-news-item-pubdate-hover:		@color-brand-primary-1;
@color-news-item-read-more:			@color-brand-primary-1;
@color-news-item-read-more-hover:	@color-brand-primary-3;
@color-news-item-title:				@color-brand-primary-2;
@color-news-item-pubdate:			@color-brand-primary-1;

//*Other*//
@color-button:					@color-brand-primary-1;
@color-button-color:			@color-white;
@color-button-hover:			@color-brand-primary-3;

@color-gototop:					@color-brand-primary-1;
@color-gototop-hover:			@color-brand-primary-3;

@color-link:					@color-brand-tertiary-1;
@color-link-hover:				@color-brand-primary-3;

@color-call-us-button:			@color-brand-primary-2;

@color-pagination-current:		@color-brand-primary-1;

@color-icon-back:				@color-brand-tertiary-1;

@color-list-second-level:		@color-brand-primary-1;


@color-1st-bulletpoint:			@color-brand-primary-3;

@color-list-first-level:		@color-brand-primary-1;
@color-list-second-level:		@color-brand-primary-3;

@color-text-widget-date-text-color:		@color-brand-secondary-1;

@color-day-block-fill:			@color-brand-secondary-1;
@color-text-widget-date-day:	@color-brand-secondary-1;

@color-month-block-fill:		@color-brand-secondary-1;
@color-text-widget-date-month:	@color-brand-secondary-1;

@color-slogan: none;

@color-carousel-background:		#F2F2F2;

@color-icon-contact: @color-brand-primary-3;

//*Fonts*/
@base_font-family: 'Titillium Web', sans-serif;
@base_second_font-family: @base_font-family;

@header-font-family: @base_font-family;
@menu-font-family: @base_font-family;

@base_font-size: 16px;
@small_font-size: @base_font-size;
@medium_font-size: 16px;
@large_font-size: 16px;
@base-line-height: 24px;

@font-size-menu-items: 20px;
@font-size-header-items: 1.25rem;

//*Breakpoints*/
@small_breakpoint-max: 767px;
@medium_breakpoint-min: (@small_breakpoint-max + 1px);
@medium_breakpoint-max: 1199px;
@large_breakpoint-min: (@medium_breakpoint-max + 1px);
@large-breakpoint-max: 1999px;

//*Sizes*//
@max-content-width:1278px;

@menu-height: 60px;
@menu-items-height: 60px;
@menu-height-xs: 60px;
@menu-items-height-xs: 60px;

@height-header: 140px;

@height-header-logo-cleaner: 100px;
@height-header-logo-gevelco: 80px;
@height-header-logo-gevelco-small: 87px;

//*Custom colors*//
@color-black:#000000;
@color-white:#FFFFFF;

//*Text*//
@color-text-accent-1: @color-brand-primary-1;
@color-text-accent-2: @color-brand-primary-1;
@color-text-accent-3: @color-brand-primary-1;
@color-text:		  @color-black;

@padding-small: 10px;
@padding-default: 20px;
@padding-medium: 30px;

//*Bootstrap variables*//
@screen-sm-min: @medium_breakpoint-min;
@screen-md-min: @large_breakpoint-min;
@screen-lg-min: (@large_breakpoint-min * 1.5);

@grid-gutter-width: 40px;

//*Space around content*//
@default-white-space-small: 30px;
@default-white-space-medium:40px;
@default-white-space-big:50px;