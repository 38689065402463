﻿/// <reference path="../_Variables.less" />
.content-nav-link {
	margin-top: 20px;

	a {
		margin-top: 0.5em;
		color: @color-link;
		font-weight: bold;
		text-decoration: none;
		
		&:hover,
		&:focus {
			text-decoration: underline;
			color: @color-link-hover;

			.icon svg,
			path {
				fill: @color-brand-primary-3;
			}
		}
	}
}

.pagination-container {
	margin-top: 15px;
}

.pagination {
	> li {

		> a, > span {
			min-width: 44px;
			line-height: 44px;
			vertical-align: middle;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}
