﻿/// <reference path="../_Variables.less" />

.newsOverview {
  //  border-bottom:1px #CBCBCB solid;
    .row {
     //   border-top:1px #CBCBCB solid;
		h2,span{
			color:@color-news-item-title;
			
		}

		a {
			text-decoration: none;
			&:hover{
				span,h2{
					color:@color-news-item-pubdate-hover;
				}
			}
		}
    }
    .pubdate {
        font-size:0.75em;
        font-weight:normal;
        color: @color-news-item-pubdate !important;
        display:inline-block;
        width:100%;
      
    }

    .news-image {
        
        padding-right:0;
        @media screen and (min-width:@medium_breakpoint-min) {
            padding-right:floor((@grid-gutter-width / 2));
        }
    }

	.read-more {
		color: @color-news-item-read-more;

		&:hover {
			color: @color-news-item-read-more-hover;
		}
	}
}
