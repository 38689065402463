﻿/// <reference path="../_Variables.less" />

.row .paragraph-normal p {
	margin-bottom: 0;
}

.contact-introduction {
	word-wrap: break-word;
}

.contact-contact {
	margin-top: 10px;
}


.container-contact {
	.top-row {
		margin-bottom: 50px;
	}

	.page-title {
		margin-bottom: 20px;
	}

	.custom-col {
		.icon-telephone, .icon-location {
			path {
				fill: @color-icon-contact;
			}
		}
	}
}

.custom-col {
	@media(min-width:@screen-sm-min) {
		width: 47%;
	}

	.google-maps {
		padding: 45% 0;
		margin-top: 0;
	}

	.icon {
		vertical-align: top;
	}

	.icon + div {
		display: inline-block;
		margin-top: 15px;
		margin-left: 15px;
	}
}

form {
	label {
		margin-bottom: 5px !important;
	}

	span {
		margin-bottom: 10px;
	}

	p:nth-last-child(2) {
		margin-top: 15px;
	}
}

.container-contact {
	form {
		input {
			padding: 7px !important;
		}
	}
}
