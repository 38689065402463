﻿/// <reference path="../_Variables.less" />

.header-container {
	background: @color-header-background;
	color: @color-text-accent-1;
	padding-top: 5px;
	font-family: @menu-font-family;
	height: @height-header;

	.header-element {
		/*padding-left:100px;*/
		a {
			text-decoration: none;
		}
	}

	.header-logo-container {
		margin-bottom: 0;
		text-decoration: none;

		@media(max-width: @small_breakpoint-max) {
			padding: 0 10px;
			width: 100%;
			display: flex;
			align-items: center;

		}

		.logo-gevelco {
			height: @height-header-logo-gevelco;
		}

		.logo-gevelco-small {
			height: @height-header-logo-gevelco-small;

			@media(max-width: @screen-sm-min) {
				float: right;
				margin-left: auto;
				margin-top: 8px;
			}
		}

		.logo-cleaner {
			height: @height-header-logo-cleaner;
			margin-right: 10px !important;

			@media(max-width: 400px) {
				height: @height-header-logo-cleaner - 20px !important;
			}

			&.right {
				@media(min-width: @screen-sm-min) {
					margin-right: 35px !important;
				}
			}
		}
		/*.logo-gevelco, .logo-cleaner {
			@media(min-width: @small_breakpoint-max) {
				display: inline-block;
				margin: 0 auto;
				vertical-align: middle;
			}
		}*/
		.img-responsive {
			display: inline-block;
			margin: 0 auto;
			vertical-align: middle;
			min-height: 100px;
			//min-width: 186px;
		}
	}
}

.header {
	padding-top: 15px;
	padding-bottom: 20px;

	a {
		color: @color-header-link;

		&:hover, &:focus {
			color: @color-header-link-hover;

			path {
				fill: @color-header-link-hover;
			}
		}

		path {
			fill: @color-header-link;
		}
	}

	.pros-col {
		text-align: left;
		padding-left: 0;

		.header-element {
			/*padding-left:100px;*/
			a {
				text-decoration: none;
			}
		}

		p {
			color: @color-brand-primary-1;
		}
	}

	.header-slogan {
		font-size: 30px;
		font-style: italic;
		color: @color-slogan;
	}

	@media screen and (min-width: @small_breakpoint-max) {
		.row {
			/*width:100%;*/
			.block-link {
				/*width:100%;
				display:block;
				float:none;*/
				//float: left;
				.img-responsive {
					float: none;
					margin: 0 0;
				}
			}
		}
	}

	@media screen and (min-width: @medium_breakpoint-max) {
		.header-item {
			padding-top: 45px;
			/*.header-element {
				height:30px;
			}*/
		}
		/*.col-phone{
			padding-top:22px!important;
			.header-element{
				margin-left:-5px;
			}
		}*/
		/*.col-mail{
			margin-top:-1px;
		}*/
	}
}



.header-icon {

	svg {
		vertical-align: middle;

		path {
			fill: @color-header-icon;
		}
	}
	/*width: 24px;
	height: 38px;*/
	/*svg{
		bottom: 0;
	}*/
}

@media screen and (min-width:@large_breakpoint-min) {
	.header-col {
		display: table;
	}
}

@media screen and (max-width: @medium_breakpoint-min) {
	.header-col {
		padding: 0 10px;
	}
}

.header-element {
	height: 60px; // 100px?
	display: table-cell;
	vertical-align: middle;

	p {
		margin: 0;

		a {
			font-weight: normal;
		}
	}
}

// different parts of the header


// phone

.header-item {
	text-align: right;

	p {
		font-size: @font-size-header-items;
		color: @color-paragraph;
	}

	.icon-phone, .icon-mail {
		margin-right: 5px;
		margin-bottom: 1px;
	}

	.icon-mail {
		width: 24px;
		height: 16px;
	}

	.icon-phone {
		width: 24px;
		height: 24px;
	}
	/*..icon-mail{
		padding-top:8px;
	}*/
	.icon-vinkje {
		margin-top: -7px;
	}
	/*@media screen and (min-width: @large_breakpoint-min) {
		.icon-phone{
			margin-left:-40px;
		}
	}*/
}

// language select

.header-language {
	@color-header-language: @color-black;
	@color-header-language-alt: @color-brand-primary-1;
	line-height: 20px;
	text-align: right;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 12px;
	position: absolute;
	right: 10px;
	top: 10px;
	color: @color-header-language;
	cursor: pointer;

	@media screen and (min-width:500px) {
		font-size: 18px;
	}

	@media screen and (max-width: @medium_breakpoint-min) {
		.header-element {
			width: 45vw;
		}
	}

	label {
		position: relative;
		font-weight: normal;

		&:hover, &:hover:after, &:hover:before {
			color: @color-header-language-alt;
			cursor: pointer;
		}

		&:hover, &:focus {
			.header-selected-language {
				color: @color-header-language-alt;
			}

			.header-language-select {
				color: @color-header-language-select;
			}
		}

		.header-language-select {
			color: @color-header-language-select;
			cursor: pointer;
		}

		margin-bottom: 0;

		&:before {
			font-family: 'Glyphicons Halflings';
			content: '\e114';
			display: block;
			position: absolute;
			bottom: auto;
			right: 5px;
			top: 0;
			margin-right: -0.75em;
			color: @color-header-language;
			font-size: 10px;

			@media screen and (min-width:500px) {
				margin-right: 0;
				font-size: 0.75em;
			}

			@media screen and (min-width:@large_breakpoint-min) {
				top: 0px;
			}
		}
	}

	.change-language-text {

		@media screen and (max-width:@large_breakpoint-min) {
			display: inline-block;
		}
	}

	.header-language-select {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		top: 0;
		bottom: 0;
		opacity: 0;
		filter: alpha(opacity=0);
		background: @color-menu-background;
		color: @color-header-language;
		border: 0;
		cursor: pointer;

		option {
			cursor: pointer;
		}

		&:hover {
			color: @color-header-language-alt;
			cursor: pointer;
		}

		font-size: 12px;

		@media screen and (min-width:500px) {
			font-size: 18px;
		}
	}

	.header-selected-language {
		color: @color-header-language;
		font-size: 12px;
		display: inline-block;
		padding-right: 1.5em;
		cursor: pointer;


		&:hover {
			color: @color-header-language-alt;
			cursor: pointer;
		}

		@media screen and (min-width:500px) {
			font-size: 18px;
		}
	}
}
