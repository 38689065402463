﻿/// <reference path="../_Variables.less" />

.menubar {
    background: @color-menu-background;
    position: relative;
}

.nav-main {
	.nav-main-items {
		background: @color-menu-background;

		@media screen and (max-width:@medium_breakpoint-max) {
			background: @color-menu-background;
		}

		.menu-items {
			height: @menu-items-height;
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: @font-size-menu-items;
			font-family: @menu-font-family;

			& > li {
				&.active-menuitem a {
					border-bottom: 4px solid @color-active-menu-item;
				}
			}

			li {
				height: @menu-items-height;
				margin: 0;
				padding: 0;
				float: left;

				a {
					height: @menu-items-height;
					line-height: @menu-items-height;
					vertical-align: middle;
					padding: 0 35px;
					border-bottom: 4px solid @color-active-menu-item;
					border-bottom: 4px solid rgba(0,0,0,0);
					font-weight: normal;
				}
				&:hover {
					background-color:@color-active-menu-item;
					border-bottom-color: @color-active-menu-item;
					color: darken(@color-text-accent-2,20%);
					path {
						fill: @color-text-accent-3;
					}

					a{
						color:@color-white;
					}
				}
				&.menu-more-dropdown {
					a {
						padding-right: 5px;
					}
				}
			}
		}
	}

	.menu-dropoutmenu {
        background: @color-menu-background;
        //  position: relative;
        width: 100%;
        height: 0;

        @media screen and (min-width:@medium_breakpoint-min) {
            position: relative;
        }

        ul {
            position: absolute;
            left: 0;
            right: 0;

            @media screen and (min-width:@medium_breakpoint-min) {
                position: static;
            }

            margin: 0;
            z-index: 100;
            padding: 0;
            list-style: none;
            border-bottom: 1px solid @color-menu-background;

            li {
                height: @menu-items-height;
                line-height: @menu-items-height - 2px;
                vertical-align: middle;
                margin: 0;
                padding: 0 15px;
                border-top: 1px solid  @color-menu-background;
                background: @color-menu-background;

                &.active-menuitem {
                    background: @color-active-menu-item;
                }
				&:hover {
					background-color: @color-active-menu-item;
					border-bottom-color: @color-menu-background;
					color: darken(@color-text-accent-2,20%);

					path {
						fill: darken(@color-text-accent-2,20%);
					}
				}
            }
        }

        @media screen and (min-width:@medium_breakpoint-min) {
            float: right;

            ul {
                right: 15px;
                position: absolute;
                left: auto;
                top: 0;

                li {
                    padding: 0 30px;
                }
            }
        }
    }

    .nav-main-call-col {
        background-color: @color-call-us-button;
		font-family: @menu-font-family;
    }

    &.menu-open {
        .menu-toggle {
            color: @color-menu-title-mobile;
			
            path {
                fill: @color-menu-icon-mobile;
            }
        }

        .dropmenu-icon-open-xs {
            display: inline-block;
        }

        @media screen and (min-width:@medium_breakpoint-min) {
            .dropmenu-icon-open {
                display: inline-block;
            }

            .dropmenu-icon-open-xs {
                display: none;
            }
        }

        .dropmenu-icon-closed {
            display: none;
        }
    }

    .dropmenu-icon {
        width: 16px;
        height: 16px;
        display: inline-block;

        &.dropmenu-icon-right {
            float: right;
            margin-top: ((@menu-height - 15px) / 2);
        }
    }

    .dropmenu-icon-closed {
        display: inline-block;
        margin-left: 4px;
        margin-top: 4px;
    }

    .dropmenu-icon-open, .dropmenu-icon-open-xs {
        display: none;
        margin-left: 4px;
        margin-top: 2px;
    }

    .nav-main-call-col, .nav-main-items {
        height: @menu-height;
        line-height: @menu-height - 2px;
        vertical-align: middle;
        text-align: center;

        &.showMenu {
            text-align: left !important;
        }

        @media screen and (min-width:@medium_breakpoint-min) {
            height: @menu-items-height;
            text-align: left;
        }

		@media screen and (max-width:@small_breakpoint-max) {
            height: @menu-height-xs;
			line-height: @menu-height-xs;
            text-align: left;
			font-size: 1.25em;
			text-align: center;
        }
    }

    .menu-icon {
        width: 24px;
        height: 24px;
        margin-right: 15px;

        path {
            fill: @color-menu-icon-mobile;
        }
    }

    

    .menu-xs-container {
		font-family: @menu-font-family;
        @media screen and (min-width:@medium_breakpoint-min) {
            .js & {
                display: none;
            }
        }
    }

    

    a {
        display: block;
        color: @color-menu-text;
        text-decoration: none;

        /*&:hover {
			background-color:@color-brand-primary-1;
            border-bottom-color: @color-brand-primary-1;
            color: darken(@color-text-accent-2,20%);

            path {
                fill: darken(@color-text-accent-2,20%);
            }
        }*/
    }
}