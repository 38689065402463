﻿/// <reference path="../_Variables.less" />

.img-block {
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (min-width:@medium_breakpoint-min) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.first-image{
	margin-top: 10px;

	@media screen and (max-width: @small_breakpoint-max){
		.img-responsive {
			max-height:250px;
			margin:0 auto;
		}
	}

}

.google-maps{ 
	height: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    overflow: hidden;
    position: relative;
	iframe { 
		position: absolute; 
		top:0; 
		left:0; 
		width:100%; 
		height:100%;
	}
}

.google-maps{
	padding-bottom: 40%;
    padding-right: 40%;
}

.google-maps-contact{
	margin-top: 50px;
	margin-right:0!important;
}

@media screen and (min-width:@large_breakpoint-min){
	.youtube-frame {
		max-width:50%;
	}
}