﻿/// <reference path="../_Variables.less" />
/// <reference path="../Bootstrap.less" />

@review-border-color: #BBBDBF;
@review-triangle-width: 35px;
@review-triangle-width-xs: @review-triangle-width - 15px;

.review {
	margin-top: 40px;

	.review-person {
		text-align: center;
		color: black;
	}

	.icon {
		margin-bottom: 10px;

		svg {
			circle {
				fill: @color-brand-primary-1;
			}
		}
	}

	

	.review-content {
		border: 1px solid @review-border-color;
		border-radius: 5px;
		padding: 40px;
		margin-left: @review-triangle-width;
		background-color: white; 

		@media(max-width: @screen-xs-max) {
			padding: 20px;
		}

		.pubdate {
			font-weight: 600 !important;
			margin-bottom: 10px;
		}

		h2 {
			font-size: 20px;
			font-weight: normal !important;
			margin-top: 0;
			margin-bottom: 10px;

			@media(min-width: @screen-md-min) {
				font-size: 18px;
			}
		}
	}
}

.review-cms {
	//background-color: #f2f2f2;
}

.triangle {
  position: relative;
}

.triangle:before {

	content: "";
	display: block;
	position: absolute;
	top: 25px;
	left: @review-triangle-width * -1;
	width: 0;
	border-width: 0 @review-triangle-width 30px 0;
	border-color: transparent @review-border-color;
	border-style: solid;

	@media(max-width: @screen-xs-max) {
		left: @review-triangle-width-xs * -1;
		border-width: 0 @review-triangle-width-xs 30px 0;
	}
}

.triangle:after {
	content: "";
    position: absolute;
    border-width: 0 @review-triangle-width - 1 29px 0;
    border-style: solid;
    display: block;
    width: 0;
    top: 26px;
    left: @review-triangle-width *-1 + 3;
    border-color: transparent #fff;

	@media(max-width: @screen-xs-max) {
		border-width: 0 @review-triangle-width-xs - 1 29px 0;
		left: @review-triangle-width-xs *-1 + 3;
	}
}

div[class^="col-xs-"] {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}